/* eslint-disable no-param-reassign */
import { useContext } from 'react';
import { TranslatorContext } from '@jutro/locale';
import PartnerContext from '../../contexts/PartnerContext/PartnerContext';
import ClientContext from '../../contexts/ClientContext/ClientContext';

function CustomHeaderLogo(props) {
    const parnerContext = useContext(PartnerContext);
    const clientTypeContext = useContext(ClientContext);
    const translator = useContext(TranslatorContext);

    const redirectionDetails = clientTypeContext.getRedirectionDetails();
    return parnerContext.generateHeaderLogo(translator, redirectionDetails);
}

export default CustomHeaderLogo;
