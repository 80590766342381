/* eslint-disable max-len */
import React from 'react';
import { Link } from 'react-router-dom';
import { ImageComponent } from 'gw-components-platform-react';
import styles from './CardoenPartnerValuesProvider.module.scss';
import defaultMessages from '../default/DefaultPartnerValuesProvider.messages';

export default class CardoenPartnerValuesProvider {
    generateHeaderLogo = (translator, redirectionDetails) => {
        const { baseUrlForClient, state } = redirectionDetails;
        return (
            <div>
                <Link
                    to={{ pathname: baseUrlForClient, state: state }}
                    className={styles.ingLinkStyles}
                >
                    <div className={styles.logoContainer}>
                        <ImageComponent src="/logo-cardoen.svg" className={styles.ingLogosStyle} />
                    </div>
                </Link>
            </div>
        );
    }

    partnerPhoneNumber = (translator) => {
        return translator(defaultMessages.defaultPartnerPhoneNumber);
    }

    partnerUrgentAssistLabel = (translator) => {
        return translator(defaultMessages.defaultPartnerUrgentHelpLabel, { partnerPhoneNumber: this.partnerPhoneNumber(translator) });
    }

    partnerShortLabelWithPhoneNumber = (translator) => {
        return translator(defaultMessages.defaultPartnerShortHelpWithNumber, { partnerPhoneNumber: this.partnerPhoneNumber(translator) });
    }

    partnerTypeAllowedForPVBroker = () => {
        return false;
    }
}
