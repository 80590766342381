/* eslint-disable max-len */
import _ from 'lodash';
import React, { useCallback, useContext, useState } from 'react';
import { WizardPage, WizardPageTemplate, wizardProps } from 'gw-portals-wizard-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { withRouter } from 'react-router-dom';
import { TranslatorContext } from '@jutro/locale';
import { FNOLService } from 'nn-capability-fnol';
import { isAutoLOB, isPropertyLOB, isMotorLOB, getLobByCode, isBikeLOB } from '../../utils/PolicyTypeUtil';
import styles from './WizardFNOLSummaryPage.module.scss';
import metadata from './WizardFNOLSummaryPage.metadata.json5';
import messages from './WizardFNOLSummaryPage.messages';
import contactDetailsMessages from '../WizardFnolContactDetailsPage/WizardFNOLContactDetailsPage.messages';
import { isLegalAssistanceAuto, isDamageTypeOther } from '../../utils/DamageTypesUtil';
import PartnerContext from '../../contexts/PartnerContext/PartnerContext';
import WizardFNOLSummaryAuto from '../../components/WizardFNOLSummaryVehicle/WizardFNOLSummaryAuto/WizardFNOLSummaryAuto';
import WizardFNOLSummaryProperty from '../../components/WizardFNOLSummaryProperty/WizardFNOLSummaryProperty';
import WizardFnolSummaryMotor from '../../components/WizardFNOLSummaryVehicle/WizardFNOLSummaryMotor/WizardFNOLSummaryMotor';
import WizardFNOLSummaryEBike from '../../components/WizardFNOLSummaryEBike/WizardFNOLSummaryEBike';
import { redirectToContactCallCenter, redirectToAuthContactCallCenter } from '../../utils/RedirectionUtil';
import { getTypecodeTranslation } from '../../utils/TypeCodeTranslationUtil';
import { buildAddressString, buildDateString, buildBankAccountDetails } from './CommonSummaryFunctions';
import { generateAutoPartSnapshot } from '../../components/WizardFNOLSummaryVehicle/WizardFNOLSummaryAuto/AutoSummarySnapshotGenerator';
import { generateMotorPartSnapshot } from '../../components/WizardFNOLSummaryVehicle/WizardFNOLSummaryMotor/MotorSummarySnapshotGenerator';
import { generatePropertyPartSnapshot } from '../../components/WizardFNOLSummaryProperty/PropertySummarySnapshotGenerator';
import { generateEBikePartSnapshot } from '../../components/WizardFNOLSummaryEBike/EBikeSummarySnapshotGenerator';
import useBreakpointHandler from '../../hooks/useBreakpointHandler';

function WizardFNOLSummaryPage(props) {
    const translator = useContext(TranslatorContext);
    const partnerContext = useContext(PartnerContext);
    const {
        history,
        wizardData,
        updateWizardData
    } = props;

    const [
        isMobileDevice
    ] = useBreakpointHandler();
    const isMobile = isMobileDevice();
    const [isDataConsentTicked, setIsDataConsentTicked] = useState(false);

    const buildSupportingInformation = useCallback(() => {
        let documentString = '';
        const uploadedDocuments = wizardData.value.documentAttachments;

        if (_.isEmpty(uploadedDocuments)) {
            return documentString;
        }

        uploadedDocuments.forEach((document) => {
            documentString += getTypecodeTranslation(translator, document.documentType, 'inlb_FrontEndDocumentType');
            documentString += `, ${translator(messages.wizardFnolSummaryPageFiles)} :`;
            document.files.forEach((documentFile) => {
                documentString += `\n\t- ${documentFile.fileName}`;
            });
            documentString += '\n';
        });

        return documentString;
    }, [translator]);

    const buildOtherPartiesInformation = useCallback(() => {
        let otherPartiesString = '';
        const filteredParties = wizardData.value.additionalParties;

        if (_.isEmpty(filteredParties)) return translator(messages.wizardFnolSummaryPageNoOtherPartiesInvolved);

        filteredParties.forEach((additionalParty) => {
            switch(additionalParty.canIdentifyParty) {
                case 'yes':
                    otherPartiesString += `${getTypecodeTranslation(translator, additionalParty.involvementType, 'inlb_FrontEndContactInv')}, ${getTypecodeTranslation(translator, additionalParty.contactRole, 'inlb_FrontEndContactRole')}`;
                    const contactDetails = additionalParty.contact;

                    if (contactDetails.contactType === 'company') {
                        if (contactDetails.companyName) otherPartiesString += `\n${contactDetails.companyName}`;
                        if (contactDetails.languageType) otherPartiesString += `\n${getTypecodeTranslation(translator, contactDetails.languageType, 'LanguageType')}`;
                    } else {
                        otherPartiesString += `\n${contactDetails.firstName} ${contactDetails.lastName}`;
                        otherPartiesString += `\n${getTypecodeTranslation(translator, contactDetails.gender, 'GenderType')}`;
                        otherPartiesString += `, ${getTypecodeTranslation(translator, contactDetails.languageType, 'LanguageType')}`;
                        if (additionalParty.wasInjured !== undefined) {
                            otherPartiesString += additionalParty.wasInjured
                                ? `\n${translator(messages.wizardFnolSummaryPageDriverInjured)}`
                                : `\n${translator(messages.wizardFnolSummaryPageDriverNotInjured)}`;
                        }
                    }
                    if (contactDetails.emailAddress && contactDetails.phoneNumber) otherPartiesString += `\n${contactDetails.emailAddress} - ${contactDetails.phoneNumber}`;
                    else {
                        if (contactDetails.emailAddress) otherPartiesString += `\n${contactDetails.emailAddress}`;
                        if (contactDetails.phoneNumber) otherPartiesString += `\n${contactDetails.phoneNumber}`;
                    }

                    const { address } = contactDetails;
                    if (!_.isEmpty(address)) {
                        otherPartiesString += `\n${buildAddressString(address, translator)}`;
                    }

                    if (additionalParty.licensePlate) otherPartiesString += `\n${additionalParty.licensePlate}`;

                    if (!_.isEmpty(additionalParty.insurerName) || !_.isEmpty(additionalParty.policyNumber)) {
                        otherPartiesString += '\n';
                        if (additionalParty.insurerName) otherPartiesString += `${additionalParty.insurerName}, `;
                        if (additionalParty.policyNumber) otherPartiesString += `${additionalParty.policyNumber}, `;
                        otherPartiesString = otherPartiesString.slice(0, -2);
                    }
                    if (additionalParty.reportNumber) otherPartiesString += `\n${additionalParty.reportNumber}`;
                    otherPartiesString += '\n\n';
                    break;
                case 'no':
                    otherPartiesString += translator(messages.wizardFnolSummaryPageDriverUnidentifiedParty);
                    otherPartiesString += '\n\n';
                    break;
                case 'yesWithEAS':
                    otherPartiesString += translator(messages.wizardFnolSummaryPagePartyWithEAS);
                    otherPartiesString += '\n\n';
                    break;
            }
        });
        otherPartiesString = otherPartiesString.slice(0, -2);
        return otherPartiesString;
    }, [translator, wizardData.value.additionalParties]);

    const buildReporterDetailsInformation = useCallback(() => {
        const reporter = wizardData.reporterDetails.value;
        let driverDetails = `${reporter.firstName} ${reporter.lastName} - ${buildDateString(reporter.birthDate)}`;

        if (reporter.emailAddress && reporter.phoneNumber) {
            driverDetails += `\n${reporter.emailAddress} - ${reporter.phoneNumber}`;
        } else {
            if (reporter.emailAddress) driverDetails += `\n${reporter.emailAddress}`;
            if (reporter.phoneNumber) driverDetails += `\n${reporter.phoneNumber}`;
        }

        if (wizardData.supportiveValues.value.reporterContactFoundOnPolicy === true) return driverDetails;

        if (reporter.gender !== undefined) {
            driverDetails += `\n${getTypecodeTranslation(translator, reporter.gender, 'GenderType')}`;
        }

        if (reporter.languageType !== undefined) {
            driverDetails += `\n${getTypecodeTranslation(translator, reporter.languageType, 'LanguageType')}`;
        }

        if (reporter.relationToPolicyOwner !== undefined) {
            driverDetails += `\n${getTypecodeTranslation(translator, reporter.relationToPolicyOwner, 'PersonRelationType')}`;
        }

        if (wizardData.isBrokerFlow.value !== true && !isLegalAssistanceAuto(wizardData.frontEndDamageTypes) && isAutoLOB(wizardData.lob.value)) {
            const wasInjured = reporter.wasInjured !== undefined
                ? reporter.wasInjured
                : wizardData.vehicleDamage.wasTheDriverInjured.value;

            if (wasInjured) {
                driverDetails += `\n${translator(messages.wizardFnolSummaryPageDriverInjured)}`;
                if (isAutoLOB(wizardData.lob.value)) {
                    if (reporter.wasInjured && reporter.injuredAsPassenger !== undefined) {
                        driverDetails += `\n${translator(contactDetailsMessages.fnolContactDetailsIsInjuredInvolvement)} - `;
                        driverDetails += reporter.injuredAsPassenger
                            ? `${translator(contactDetailsMessages.fnolContactDetailsIsInjuredInvolvementPassenger)}`
                            : `${translator(contactDetailsMessages.fnolContactDetailsIsInjuredInvolvementPersonOutsideVehicle)}`;
                    }
                }
            } else {
                driverDetails += `\n${translator(messages.wizardFnolSummaryPageDriverNotInjured)}`;
            }
        }

        const { address } = reporter;
        if (!_.isEmpty(address)) {
            driverDetails += `\n${buildAddressString(address, translator)}`;
        }

        if (!_.isEmpty(reporter.bankAccountDetails)) {
            driverDetails += buildBankAccountDetails(reporter.bankAccountDetails, translator);
        }

        return driverDetails;
    }, [wizardData.reporterDetails.value,
        wizardData.frontEndDamageTypes,
        wizardData.vehicleDamage,
        wizardData.lob.value, translator]);

    const buildDamageTypeDescription = useCallback((damageType) => {
        let damageTypeDescription = getTypecodeTranslation(translator, damageType, 'inlb_FrontEndDamageType');
        const vmNodeDamageType = wizardData.frontEndDamageTypes.children.filter((dmgType) => { return dmgType.value.code === damageType; });
        if (isDamageTypeOther(vmNodeDamageType[0])) {
            damageTypeDescription += ` - ${wizardData.value.damageTypeOtherDescription}`;
        }
 
        const { pickedQuestionSets } = wizardData.value;
        if (_.isEmpty(pickedQuestionSets)) return damageTypeDescription;

        const pickedQuestionSetsForDamage = pickedQuestionSets.filter((qs) => qs.damageType === damageType);
        if (_.isEmpty(pickedQuestionSetsForDamage)) return damageTypeDescription;

        pickedQuestionSetsForDamage[0].availableQuestions.forEach((availableQuestion) => {
            damageTypeDescription += `\n  - ${getTypecodeTranslation(translator, availableQuestion, 'inlb_QuestionSet')}`;
            if (availableQuestion === 'otherqs') damageTypeDescription += ` - ${pickedQuestionSetsForDamage[0].otherDescription}`;
        });

        return damageTypeDescription;
    }, [translator, wizardData.frontEndDamageTypes, wizardData.value.damageTypeOtherDescription,
        wizardData.value.pickedQuestionSets, wizardData.supportiveValues.value.availableQuestionSets]);


    const buildDamageTypesDescription = useCallback(() => {
        let damageTypeDescription = '';
        const damageTypes = wizardData.value.frontEndDamageTypes;
        if (_.isEmpty(damageTypes)) return damageTypeDescription;

        if (damageTypes.length === 1) {
            damageTypeDescription = buildDamageTypeDescription(wizardData.value.frontEndDamageTypes[0]);
        } else {
            damageTypes.forEach((dmgType) => {
                damageTypeDescription += `${buildDamageTypeDescription(dmgType)},\n`;
            });
            damageTypeDescription = damageTypeDescription.slice(0, -2);
        }
        return damageTypeDescription;
    }, [wizardData.value.frontEndDamageTypes, buildDamageTypeDescription]);

    const writeValue = useCallback((value, path) => {
        _.set(wizardData.value, path, value);
        updateWizardData(wizardData);
    }, [wizardData, updateWizardData]);

    const submitClaim = useCallback(() => {
        const wizardValue = wizardData.value;

        if (!wizardValue.summaryLabelValues) {
            wizardValue.summaryLabelValues = [];
        }

        wizardValue.summaryLabelValues.push({
            label: translator(messages.wizardFnolSummaryPagePolicyNumber),
            value: wizardValue.policyNumber
        });

        wizardValue.summaryLabelValues.push({
            label: translator(messages.wizardFnolSummaryPageDateOfLoss),
            value: buildDateString(wizardValue.lossDate)
        });

        if (wizardValue.frontEndClaimCause) {
            wizardValue.summaryLabelValues.push({
                label: translator(messages.wizardFnolSummaryPageDeclarationCause),
                value: getTypecodeTranslation(translator, wizardValue.frontEndClaimCause, 'inlb_FrontEndClaimCause')
            });
        }

        if (wizardValue.firstQuestionAnswer) {
            wizardValue.summaryLabelValues.push({
                label: getTypecodeTranslation(translator, wizardValue.firstQuestion, 'inlb_FrontEndQuestion'),
                value: getTypecodeTranslation(translator, wizardValue.firstQuestionAnswer, 'inlb_FrontEndAnswer')
            });
        }

        if (wizardValue.secondQuestionAnswer) {
            wizardValue.summaryLabelValues.push({
                label: getTypecodeTranslation(translator, wizardValue.secondQuestion, 'inlb_FrontEndQuestion'),
                value: getTypecodeTranslation(translator, wizardValue.secondQuestionAnswer, 'inlb_FrontEndAnswer')
            });
        }

        if (wizardData.value.frontEndLossCause) {
            wizardValue.summaryLabelValues.push({
                label: translator(messages.wizardFnolSummaryPageLossCause),
                value: getTypecodeTranslation(translator, wizardData.value.frontEndLossCause, 'inlb_FrontEndLossCause')
            });
        }

        if (!_.isEmpty(wizardData.value.frontEndDamageTypes)) {
            wizardValue.summaryLabelValues.push({
                label: translator(messages.wizardFnolSummaryPageDamageType),
                value: buildDamageTypesDescription()
            });
        }

        wizardValue.summaryLabelValues.push({
            label: translator(messages.wizardFnolSummaryPageWhatHappend),
            value: wizardValue.whatHappened
        });

        if (!_.isEmpty(wizardData.value.lossLocation)) {
            wizardValue.summaryLabelValues.push({
                label: translator(messages.fnolSummaryWhereDidItHappen),
                value: buildAddressString(wizardData.value.lossLocation, translator)
            });
        }

        // Implementation specific for particular LOB.
        const lob = getLobByCode(wizardData.value.lob);
        switch (lob) {
            case 'auto':
                wizardValue.summaryLabelValues = wizardValue.summaryLabelValues
                    .concat(generateAutoPartSnapshot(translator, wizardData));
                break;
            case 'property':
                wizardValue.summaryLabelValues = wizardValue.summaryLabelValues
                    .concat(generatePropertyPartSnapshot(translator, wizardData));
                break;
            case 'motor':
                wizardValue.summaryLabelValues = wizardValue.summaryLabelValues
                    .concat(generateMotorPartSnapshot(translator, wizardData));
                break;
            case 'bike':
                wizardValue.summaryLabelValues = wizardValue.summaryLabelValues
                    .concat(generateEBikePartSnapshot(translator, wizardData));
                break;
            default:
                break;
        }

        if (!_.isEmpty(buildSupportingInformation())) {
            wizardValue.summaryLabelValues.push({
                label: translator(messages.wizardFnolSummaryPageSupportingInformation),
                value: buildSupportingInformation()
            });
        }

        wizardValue.summaryLabelValues.push({
            label: translator(messages.wizardFnolSummaryPageOtherParties),
            value: buildOtherPartiesInformation()
        });

        wizardValue.summaryLabelValues.push({
            label: translator(messages.wizardFnolSummaryPageReporterDetails),
            value: buildReporterDetailsInformation()
        });

        wizardValue.progress = 'claimCreated';
        return FNOLService.finalizeClaim(wizardValue, history, { partnerContext, translator }).then(
            (responseDTO) => {
                if (responseDTO.error !== undefined) {
                    if (responseDTO.error.errorCode !== "500") {
                        redirectToAuthContactCallCenter(history, true);
                    } else {
                        redirectToContactCallCenter(
                            history,
                            messages.wizardFnolRedirectToContactPageTitle,
                            messages.wizardFnolRedirectToContactPageSubTitle,
                            true,
                            false,
                            true
                        );
                    }
                } else {
                    wizardData.value = responseDTO;
                    history.push({
                        pathname: '/confirmation-page',
                        state: {
                            redirectPath: '/',
                            fnolDTO: wizardData.value
                        }
                    });
                }
            }
        ).catch(() => {
            redirectToContactCallCenter(
                history,
                messages.wizardFnolRedirectToContactPageTitle,
                messages.wizardFnolRedirectToContactPageSubTitle,
                true,
                false,
                true
            );
        });
    }, [wizardData]);

    const generateHeader = () => {
        return (
            <div>
                <h1>
                    {translator(messages.wizardFnolSummaryPageTitle)}
                </h1>
                <h1>
                    {wizardData.value.claimNumber}
                </h1>
            </div>
        );
    };

    const overrides = {
        fnolSummaryPolicyNumber: {
            labelPosition: isMobile ? 'top' : 'left'
        },
        fnolSummaryLossDate: {
            value: buildDateString(wizardData.value.lossDate),
            labelPosition: isMobile ? 'top' : 'left'
        },
        fnolSummaryCauseDeclaration: {
            visible: !!wizardData.value.frontEndClaimCause,
            value: getTypecodeTranslation(translator, wizardData.value.frontEndClaimCause, 'inlb_FrontEndClaimCause'),
            labelPosition: isMobile ? 'top' : 'left'
        },
        fnolSummaryFirstQuestion: {
            visible: !!wizardData.value.firstQuestionAnswer,
            label: getTypecodeTranslation(translator, wizardData.value.firstQuestion, 'inlb_FrontEndQuestion'),
            value: getTypecodeTranslation(translator, wizardData.value.firstQuestionAnswer, 'inlb_FrontEndAnswer'),
            labelPosition: isMobile ? 'top' : 'left'
        },
        fnolSummarySecondQuestion: {
            visible: !!wizardData.value.secondQuestionAnswer,
            label: getTypecodeTranslation(translator, wizardData.value.secondQuestion, 'inlb_FrontEndQuestion'),
            value: getTypecodeTranslation(translator, wizardData.value.secondQuestionAnswer, 'inlb_FrontEndAnswer'),
            labelPosition: isMobile ? 'top' : 'left'
        },
        fnolSummaryLossCause: {
            visible: !!wizardData.value.frontEndLossCause,
            value: getTypecodeTranslation(translator, wizardData.value.frontEndLossCause, 'inlb_FrontEndLossCause'),
            labelPosition: isMobile ? 'top' : 'left'
        },
        fnolSummaryDamageType: {
            visible: !_.isEmpty(wizardData.value.frontEndDamageTypes),
            value: buildDamageTypesDescription(),
            labelPosition: isMobile ? 'top' : 'left'
        },
        fnolSummaryWhatHappends: {
            labelPosition: isMobile ? 'top' : 'left'
        },
        fnolSummaryWhereDidItHappen: {
            visible: !_.isEmpty(wizardData.value.lossLocation),
            value: buildAddressString(wizardData.value.lossLocation, translator),
            labelPosition: isMobile ? 'top' : 'left'
        },
        fnolSummaryAuto: {
            visible: isAutoLOB(wizardData.lob.value),
            wizardDTO: wizardData,
            writeValue: writeValue
        },
        fnolSummaryMotor: {
            visible: isMotorLOB(wizardData.lob.value),
            wizardDTO: wizardData,
            writeValue: writeValue
        },
        fnolSummaryEBike: {
            visible: isBikeLOB(wizardData.lob.value),
            wizardDTO: wizardData,
            writeValue: writeValue
        },
        fnolSummaryProperty: {
            visible: isPropertyLOB(wizardData.lob.value),
            wizardDTO: wizardData,
            writeValue: writeValue
        },
        wizardFnolSummaryPageSupportingInformations: {
            visible: !_.isEmpty(buildSupportingInformation()),
            value: buildSupportingInformation(),
            labelPosition: isMobile ? 'top' : 'left'
        },
        wizardFnolSummaryPageOtherParties: {
            value: buildOtherPartiesInformation(),
            labelPosition: isMobile ? 'top' : 'left'
        },
        wizardFnolSummaryPageReporterDetails: {
            value: buildReporterDetailsInformation(),
            labelPosition: isMobile ? 'top' : 'left'
        },
        wizardFnolSummaryPageConsents: {
            value: `${translator(messages.wizardFnolSummaryPageConsentLineOne)}\n${translator(messages.wizardFnolSummaryPageConsentLineTwo)}`,
            labelPosition: isMobile ? 'top' : 'left'
        },
        wizardFnolSummaryPageNote: {
            labelPosition: isMobile ? 'top' : 'left'
        },
        wizardFnolSummaryPageDataConfirmation: {
            value: isDataConsentTicked,
            onValueChange: setIsDataConsentTicked,
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            writeValue
        },
        resolveComponentMap: {
            autoSummary: WizardFNOLSummaryAuto,
            propertySummary: WizardFNOLSummaryProperty,
            motorSummary: WizardFnolSummaryMotor,
            bikeSummary: WizardFNOLSummaryEBike
        }
    };

    return (
        <WizardPage
            template={WizardPageTemplate}
            onNext={submitClaim}
            disableNext={!isDataConsentTicked}
            nextLabel={translator(messages.wizardFnolSubmitLabel)}
            showCancel={false}
            renderContextComponent={generateHeader}
        >
            <ViewModelForm
                model={wizardData}
                uiProps={metadata.pageContent}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
                componentMap={resolvers.resolveComponentMap}
                overrideProps={overrides}
                onValueChange={writeValue}
            />
        </WizardPage>
    );
}

WizardFNOLSummaryPage.propTypes = wizardProps;
export default withRouter(WizardFNOLSummaryPage);
