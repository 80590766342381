/* eslint-disable max-len */
/* eslint-disable no-console */

import _ from 'lodash';
// eslint-disable-next-line object-curly-newline
import React, { useContext, useRef, useCallback, useEffect, useState, useMemo } from 'react';
import { WizardPage, WizardPageTemplate, wizardProps } from 'gw-portals-wizard-react';
import { ViewModelForm, ViewModelServiceContext } from 'gw-portals-viewmodel-react';
import { FNOLService } from 'nn-capability-fnol';
import { withRouter } from 'react-router-dom';
import { useValidation } from 'gw-portals-validation-react';
import { TranslatorContext } from '@jutro/locale';
import wizardConfig from '../../config/fnol-wizard-config.json5';
import styles from './WizardFNOLBasicInformationPage.module.scss';
import metadata from './WizardFNOLBasicInformationPage.metadata.json5';
import FNOLAddress from '../../components/Address/Address';
import PartnerContext from '../../contexts/PartnerContext/PartnerContext';
import ClientContext from '../../contexts/ClientContext/ClientContext';
import messages from './WizardFNOLBasicInformationPage.messages';
import useBreakpointHandler from '../../hooks/useBreakpointHandler';
import CustomRadioButton from '../../components/CustomRadioButton/CustomRadioButton';
import { isPropertyLOB, isBikeLOB } from '../../utils/PolicyTypeUtil';
import useTypelistHandler from '../../hooks/useTypelistHandler';
import { redirectToQuickFlow, redirectToContactCallCenter, redirectToExternalWebsite } from '../../utils/RedirectionUtil';
import { getTypecodeTranslation, translateTypecodeToCodeName } from '../../utils/TypeCodeTranslationUtil';
import { getMessagesBasedOnContext, getActionDataBasedOnContext } from '../../utils/ContextToRedirectionDetails';
import { isLegalAssistanceAuto } from '../../utils/DamageTypesUtil';
import { getProgressBasedOnWizardStep } from '../../utils/WizardStepToProgressUtil';
import { isLegalAssistanceProperty, isDamageTypeOther } from '../../utils/LossCausesUtil';
import useSnapshotHandler from '../../hooks/useSnapshotHandler';
import PleaseWait from '../../components/PleaseWait/PleaseWait';
import BasicInformationPropertyCivil from '../../components/BasicInformationPropertyCivil/BasicInformationPropertyCivil';
import BasicInformationPropertyLegal from '../../components/BasicInformationPropertyLegal/BasicInformationPropertyLegal';
import BasicInformationDamageTypesAndQuestionSets from '../../components/BasicInformationDamageTypesAndQuestionSets/BasicInformationDamageTypesAndQuestionSets';

function WizardFNOLBasicInformationPage(props) {
    const viewModelService = useContext(ViewModelServiceContext);
    const translator = useContext(TranslatorContext);
    const partnerContext = useContext(PartnerContext);
    const clientContext = useContext(ClientContext);
    const [isLoading, setIsLoading] = useState(false);
    const {
        history,
        currentStepIndex,
        steps,
        wizardData,
        updateWizardData,
        changeNextSteps
    } = props;
    const { getValuesFromTypelistIncludeFilter } = useTypelistHandler();

    const [
        isMobileDevice
    ] = useBreakpointHandler();

    const {
        onValidate,
        isComponentValid,
        registerComponentValidation,
        disregardFieldValidation
    } = useValidation('BasicInformationPage');


    if (!wizardData.value.lossLocation) {
        wizardData.lossLocation = {};
    }

    const [resetValuesToSnapshotIfNeeded, prepareSnapshot] = useSnapshotHandler(steps, currentStepIndex,
        updateWizardData, 'basicInformationSnapshot', history);

    const writeValue = useCallback((value, path) => {
        const {currentDTOValue} = resetValuesToSnapshotIfNeeded(wizardData, true, setIsLoading);
        if (currentDTOValue !== undefined) {
            _.set(currentDTOValue.value, path, value);
            updateWizardData(currentDTOValue);
        }
    }, [updateWizardData, setIsLoading]);

    useEffect(() => {
        if (!wizardData.value.policyType || !_.isEmpty(wizardData.value.supportiveValues.availableClaimCauses)) {
            return;
        }
        setIsLoading(true);
        const dto = viewModelService.create(
            {
                policyType: wizardData.value.policyType
            },
            'cc',
            'com.inlb.cc.extsys.edge.anonymousfnol.fnol.dto.ClaimCausesSearchDTO'
        );
        FNOLService.getAvailableFrontEndClaimCauses(dto.value, history, { partnerContext, translator }).then(
            (claimCauses) => {
                if (!claimCauses) return;
                writeValue(claimCauses, 'supportiveValues.availableClaimCauses');
            }
        ).finally(() => {
            setIsLoading(false);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getWhatHappenedValidationMessages = useMemo(() => {
        const whatHappenedValue = wizardData.value.whatHappened;

        if (whatHappenedValue === undefined || whatHappenedValue.length <= 1333) {
            return [];
        }

        return [translator(messages.wizardFNOLBasicInformationWhatHappendValidationMessage)];
    }, [wizardData.value.whatHappened, translator]);

    const componentValidation = useCallback(() => {
        const claimValue = wizardData.value;

        if (!claimValue.frontEndClaimCause) {
            return false;
        }

        if (!_.isEmpty(claimValue.supportiveValues.firstQuestionAnswers) && !claimValue.firstQuestionAnswer) {
            return false;
        }

        if (!_.isEmpty(claimValue.supportiveValues.secondQuestionAnswers) && !claimValue.secondQuestionAnswer) {
            return false;
        }

        if (!_.isEmpty(claimValue.supportiveValues.availableDamageTypes) && _.isEmpty(claimValue.frontEndDamageTypes)) {
            return false;
        }

        let availableQuestions = wizardData.value.supportiveValues.availableQuestionSets;
        if (!_.isEmpty(availableQuestions)) {
            availableQuestions = availableQuestions.filter((question) => {
                return question.availableQuestions.length > 1;
            });
        }

        if (!_.isEmpty(availableQuestions)) {
            return !_.isEmpty(wizardData.value.pickedQuestionSets);
        }

        if (!_.isEmpty(getWhatHappenedValidationMessages)) {
            return false;
        }

        return true;
    }, [wizardData.value.frontEndDamageTypes,
        wizardData.value.pickedQuestionSets, getWhatHappenedValidationMessages]);

    useEffect(() => {
        registerComponentValidation(componentValidation);
    }, [registerComponentValidation, componentValidation]);

    const createBasicInfoDTO = useCallback((initialData) => {
        return viewModelService.create(
            initialData,
            'cc',
            'com.inlb.cc.extsys.edge.anonymousfnol.fnol.dto.QuestionLossCausesSearchDTO'
        );
    }, [viewModelService]);

    const handleRedirections = useCallback(
        (responseDTO, callCenterTitle, callCenterBody, context) => {
            if (responseDTO.unableToRegisterOnlineClaim) {
                const contextMessages = getMessagesBasedOnContext(context);
                if (!_.isEmpty(contextMessages)) {
                    return Promise.resolve(redirectToContactCallCenter(history, contextMessages[0].headerMessage, contextMessages[0].bodyMessage, contextMessages[0].showTryAgaingButton, contextMessages[0].showPhoneCallButton));
                }
                return Promise.resolve(redirectToContactCallCenter(history, callCenterTitle, callCenterBody));
            }

            if (responseDTO.redirectToQuickFlow) {
                return Promise.resolve(redirectToQuickFlow(history, wizardData.value, wizardData.value.lossDate.hour !== undefined));
            }

            if (responseDTO.redirectToWebPage) {
                const contextActionData = getActionDataBasedOnContext(context);
                if (!_.isEmpty(contextActionData)) {
                    return redirectToExternalWebsite(contextActionData[0].actionData, responseDTO.redirectToWebPage);
                }
                return Promise.resolve(window.location.replace(responseDTO.redirectToWebPage));
            }
            return Promise.resolve(false);
        }, [history]
    );

    const onClaimCauseValueChange = useCallback((value) => {
        setIsLoading(true);
        const dto = createBasicInfoDTO({ frontEndClaimCause: value });

        FNOLService.handleFrontEndBasicInformationInput(dto.value, history, { partnerContext, translator }).then(
            (responseDTO) => {
                if (!responseDTO) return;
                handleRedirections(
                    responseDTO,
                    messages.wizardFNOLBasicInformationContactCallCenterRedirectTitle,
                    messages.wizardFNOLBasicInformationContactCallCenterRedirectBody,
                    value
                );

                const {currentDTOValue} = resetValuesToSnapshotIfNeeded(wizardData, true, setIsLoading);
                if (currentDTOValue !== undefined) {
                    const vmCopy = _.clone(currentDTOValue);
                    _.set(vmCopy.value, 'frontEndClaimCause', value);
                    _.set(vmCopy.value, 'firstQuestion', responseDTO.frontEndQuestion);
                    _.set(vmCopy.value, 'supportiveValues.firstQuestionAnswers', responseDTO.frontEndAnswers);
                    _.set(vmCopy.value, 'supportiveValues.firstQuestionTooltip', responseDTO.toolTip);
                    _.set(vmCopy.value, 'firstQuestionAnswer', null);
                    _.set(vmCopy.value, 'secondQuestion', null);
                    _.set(vmCopy.value, 'supportiveValues.secondQuestionAnswers', null);
                    _.set(vmCopy.value, 'supportiveValues.secondQuestionTooltip', null);
                    _.set(vmCopy.value, 'secondQuestionAnswer', null);
                    _.set(vmCopy.value, 'supportiveValues.availableLossCauses', responseDTO.lossCauses);
                    _.set(vmCopy.value, 'frontEndLossCause', null);
                    _.set(vmCopy.value, 'supportiveValues.availableDamageTypes', []);
                    _.set(vmCopy.value, 'supportiveValues.allowMultipleDamageTypesToBeSelected', null);
                    _.set(vmCopy.value, 'frontEndDamageTypes', []);
                    _.set(vmCopy.value, 'pickedQuestionSets', []);
                    _.set(vmCopy.value, 'supportiveValues.availableQuestionSets', []);
                    updateWizardData(vmCopy);
                }
            }
        ).finally(() => {
            setIsLoading(false);
        });
    }, [setIsLoading]);

    const onFirstQuestionValueChange = useCallback((value) => {
        setIsLoading(true);
        const dto = createBasicInfoDTO({ frontEndAnswer: value });

        FNOLService.handleFrontEndBasicInformationInput(dto.value, history, { partnerContext, translator }).then(
            (responseDTO) => {
                if (!responseDTO) return;
                handleRedirections(
                    responseDTO,
                    messages.wizardFNOLBasicInformationContactCallCenterRedirectTitle,
                    messages.wizardFNOLBasicInformationContactCallCenterRedirectBody,
                    value
                ).then((result) => {
                    if (result) return;

                    const frontEndDamageType = responseDTO.answerDirectDamageType ? [responseDTO.answerDirectDamageType] : null;

                    const {currentDTOValue} = resetValuesToSnapshotIfNeeded(wizardData, true, setIsLoading);
                    if (currentDTOValue !== undefined) {
                        const vmCopy = _.clone(currentDTOValue);
                        _.set(vmCopy.value, 'firstQuestionAnswer', value);
                        _.set(vmCopy.value, 'secondQuestion', responseDTO.frontEndQuestion);
                        _.set(vmCopy.value, 'supportiveValues.secondQuestionAnswers', responseDTO.frontEndAnswers);
                        _.set(vmCopy.value, 'supportiveValues.secondQuestionTooltip', responseDTO.toolTip);
                        _.set(vmCopy.value, 'secondQuestionAnswer', null);
                        _.set(vmCopy.value, 'supportiveValues.availableLossCauses', responseDTO.lossCauses);
                        _.set(vmCopy.value, 'frontEndLossCause', null);
                        _.set(vmCopy.value, 'supportiveValues.availableDamageTypes', []);
                        _.set(vmCopy.value, 'supportiveValues.allowMultipleDamageTypesToBeSelected', null);
                        _.set(vmCopy.value, 'frontEndDamageTypes', frontEndDamageType);
                        _.set(vmCopy.value, 'pickedQuestionSets', []);
                        _.set(vmCopy.value, 'supportiveValues.availableQuestionSets', []);
                        updateWizardData(vmCopy);
                    }  
                });
            }
        ).finally(() => {
            setIsLoading(false);
        });
    }, [createBasicInfoDTO, history, partnerContext, translator, handleRedirections, wizardData, updateWizardData, setIsLoading]);

    const onSecondQuestionValueChange = useCallback((value) => {
        setIsLoading(true);
        const dto = createBasicInfoDTO({ frontEndAnswer: value });

        FNOLService.handleFrontEndBasicInformationInput(dto.value, history, { partnerContext, translator }).then(
            (responseDTO) => {
                if (!responseDTO) return;
                handleRedirections(
                    responseDTO,
                    messages.wizardFNOLBasicInformationContactCallCenterRedirectTitle,
                    messages.wizardFNOLBasicInformationContactCallCenterRedirectBody,
                    value
                );

                const frontEndDamageType = responseDTO.answerDirectDamageType ? [responseDTO.answerDirectDamageType] : null;

                const {currentDTOValue} = resetValuesToSnapshotIfNeeded(wizardData, true, setIsLoading);
                if (currentDTOValue !== undefined) {
                    const vmCopy = _.clone(currentDTOValue);
                    _.set(vmCopy.value, 'secondQuestionAnswer', value);
                    _.set(vmCopy.value, 'supportiveValues.availableLossCauses', responseDTO.lossCauses);
                    _.set(vmCopy.value, 'frontEndLossCause', null);
                    _.set(vmCopy.value, 'supportiveValues.availableDamageTypes', []);
                    _.set(vmCopy.value, 'supportiveValues.allowMultipleDamageTypesToBeSelected', null);
                    _.set(vmCopy.value, 'frontEndDamageTypes', frontEndDamageType);
                    _.set(vmCopy.value, 'pickedQuestionSets', []);
                    _.set(vmCopy.value, 'supportiveValues.availableQuestionSets', []);
                    updateWizardData(vmCopy);
                }
            }
        ).finally(() => {
            setIsLoading(false);
        });
    }, [createBasicInfoDTO, history, partnerContext, translator, handleRedirections, wizardData, updateWizardData, setIsLoading]);

    const onLossCauseValueChange = useCallback((value) => {
        if (!value) {
            return;
        }
        setIsLoading(true);
        const dto = createBasicInfoDTO({ frontEndLossCause: value });

        FNOLService.handleFrontEndBasicInformationInput(dto.value, history, { partnerContext, translator }).then(
            (responseDTO) => {
                if (!responseDTO) return;

                const {currentDTOValue} = resetValuesToSnapshotIfNeeded(wizardData, true, setIsLoading);
                if (currentDTOValue !== undefined) {
                    const vmCopy = _.clone(currentDTOValue);
                    _.set(vmCopy.value, 'frontEndLossCause', value);
                    _.set(vmCopy.value, 'supportiveValues.availableDamageTypes', responseDTO.frontEndDamageTypes);
                    _.set(vmCopy.value, 'supportiveValues.allowMultipleDamageTypesToBeSelected', responseDTO.lossCauseAcceptsMultipleDamageTypes);
                    _.set(vmCopy.value, 'frontEndDamageTypes', []);
                    _.set(vmCopy.value, 'pickedQuestionSets', []);
                    _.set(vmCopy.value, 'supportiveValues.availableQuestionSets', []);
                    updateWizardData(vmCopy);
                }
            }
        ).finally(() => {
            setIsLoading(false);
        });
    }, [createBasicInfoDTO, history, partnerContext, translator, wizardData, updateWizardData, setIsLoading]);

    useEffect(() => {
        const availableLossCauses = wizardData.supportiveValues.availableLossCauses.value;
        if (!_.isEmpty(availableLossCauses) && availableLossCauses.length === 1 && !wizardData.value.frontEndLossCause) {
            onLossCauseValueChange(availableLossCauses[0]);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wizardData.supportiveValues.availableLossCauses.value]);

    useEffect(() => {
        const availableDamageTypes = wizardData.supportiveValues.availableDamageTypes.value;
        if (!_.isEmpty(availableDamageTypes) && availableDamageTypes.length === 1 && (wizardData.value.frontEndDamageTypes === null || _.isEmpty(wizardData.value.frontEndDamageTypes))) {
            writeValue([availableDamageTypes[0]], 'frontEndDamageTypes');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [writeValue, wizardData.supportiveValues.availableDamageTypes.value]);

    const buildSecondQuestionIterationData = useCallback(() => {
        const claimData = wizardData.value;
        const secondQuestionIterationData = {};
        secondQuestionIterationData.label = getTypecodeTranslation(translator, claimData.secondQuestion, 'inlb_FrontEndQuestion');
        secondQuestionIterationData.toolTip = claimData.supportiveValues.secondQuestionTooltip;
        secondQuestionIterationData.onSelect = onSecondQuestionValueChange;
        secondQuestionIterationData.availableValues = claimData.supportiveValues.secondQuestionAnswers.map((secondQAnswer) => {
            const secondQuestionData = {};
            secondQuestionData.value = translateTypecodeToCodeName(translator, secondQAnswer, 'inlb_FrontEndAnswer');
            secondQuestionData.selected = secondQAnswer === claimData.secondQuestionAnswer;
            return secondQuestionData;
        });
        return secondQuestionIterationData;
    }, [wizardData.value, onSecondQuestionValueChange, translator]);

    const buildFirstQuestionIterationData = useCallback(() => {
        const claimData = wizardData.value;

        const firstQuestionIterationData = {};
        firstQuestionIterationData.label = getTypecodeTranslation(translator, claimData.firstQuestion, 'inlb_FrontEndQuestion');
        firstQuestionIterationData.toolTip = claimData.supportiveValues.firstQuestionTooltip;
        firstQuestionIterationData.onSelect = onFirstQuestionValueChange;
        firstQuestionIterationData.availableValues = claimData.supportiveValues.firstQuestionAnswers.map((firstQAnswer) => {
            const firstQuestionData = {};
            firstQuestionData.value = translateTypecodeToCodeName(translator, firstQAnswer, 'inlb_FrontEndAnswer');
            firstQuestionData.selected = firstQAnswer === wizardData.value.firstQuestionAnswer;
            if (firstQuestionData.selected && !_.isEmpty(claimData.supportiveValues.secondQuestionAnswers)) {
                firstQuestionData.iterationData = buildSecondQuestionIterationData();
            }
            return firstQuestionData;
        });
        return firstQuestionIterationData;
    }, [buildSecondQuestionIterationData, wizardData.value, onFirstQuestionValueChange, translator]);

    const buildClaimCausesQuestionsIterationData = useCallback(() => {
        const claimData = wizardData.value;
        const avClaimCauses = claimData.supportiveValues.availableClaimCauses;
        if (_.isEmpty(avClaimCauses)) return {};

        const iterationData = {};
        iterationData.label = translator(messages.wizardFNOLBasicInformationDeclarationLabel);
        iterationData.availableValues = avClaimCauses.map((cause) => {
            const claimCauseData = {};
            claimCauseData.value = translateTypecodeToCodeName(translator, cause, 'inlb_FrontEndClaimCause');
            claimCauseData.selected = cause === claimData.frontEndClaimCause;
            if (claimCauseData.selected && !_.isEmpty(claimData.firstQuestion)) {
                claimCauseData.iterationData = buildFirstQuestionIterationData();
            }
            return claimCauseData;
        });
        iterationData.onSelect = onClaimCauseValueChange;

        return iterationData;
    }, [buildFirstQuestionIterationData, wizardData.value, onClaimCauseValueChange, translator]);

    const shouldShowSelectableAddress = useCallback(() => {
        return !_.isEmpty(wizardData.value.authenticatedFlowDetails)
            && !_.isEmpty(wizardData.value.authenticatedFlowDetails.addressFromPolicy);
    }, [wizardData.value.authenticatedFlowDetails]);

    const buildAddressAvailableValues = useCallback(() => {
        const availableAddresses = [];
        if (!shouldShowSelectableAddress()) return '';

        const policyAddress = wizardData.authenticatedFlowDetails.addressFromPolicy.value;

        let policyAddressName = `${translator(messages.wizardFNOLBasicInformationAuthenticatedAddressSelectorOnPolicy)}: `;
        if (policyAddress.street) policyAddressName += `${policyAddress.street} ${policyAddress.streetNumber}`;
        if (policyAddress.boxNumber) policyAddressName += `/${policyAddress.boxNumber}`;
        if (policyAddress.postalCode) policyAddressName += `, ${policyAddress.postalCode}`;
        if (policyAddress.city) policyAddressName += ` ${policyAddress.city}`;
        if (policyAddress.country) policyAddressName += ` (${getTypecodeTranslation(translator, policyAddress.country, 'Country')})`;

        availableAddresses.push({
            code: 'onPolicy',
            name: policyAddressName
        });

        availableAddresses.push({
            code: 'other',
            name: translator(messages.wizardFNOLBasicInformationAuthenticatedAddressSelectorOther)
        });

        return availableAddresses;
    }, [wizardData.authenticatedFlowDetails, shouldShowSelectableAddress, translator]);

    const onAddressOptionSelect = useCallback((selection) => {
        if (!selection || !shouldShowSelectableAddress()) return;
        const {currentDTOValue} = resetValuesToSnapshotIfNeeded(wizardData, true, setIsLoading);
        if (currentDTOValue !== undefined) {
            const copyOfVM = _.clone(currentDTOValue);
            _.set(copyOfVM, 'supportiveValues.lossLocationAddressType', selection);

            let newAddress;
            if (selection === 'onPolicy') {
                newAddress = wizardData.authenticatedFlowDetails.addressFromPolicy.value;
                disregardFieldValidation('wizardFNOLBasicInformationAddressInput');
            } else {
                newAddress = {};
            }
            _.set(copyOfVM.value, 'lossLocation', newAddress);
            updateWizardData(copyOfVM);
        }
    }, [shouldShowSelectableAddress, wizardData, updateWizardData, disregardFieldValidation]);

    const shouldShowWhatHappenedCommonPart = useCallback(() => {
        const claimData = wizardData.value;
        if (!claimData.frontEndClaimCause) return false;
        if (claimData.firstQuestion && !claimData.firstQuestionAnswer) return false;
        if (claimData.secondQuestion && !claimData.secondQuestionAnswer) return false;
        if (!_.isEmpty(claimData.supportiveValues.availableLossCauses) && !claimData.frontEndLossCause) return false;
        if (!_.isEmpty(claimData.supportiveValues.availableDamageTypes) && _.isEmpty(claimData.frontEndDamageTypes)) return false;
        if (!_.isEmpty(claimData.supportiveValues.availableQuestionSets) && _.isEmpty(claimData.pickedQuestionSets)) return false;
        return true;
    }, [wizardData.value]);

    const shouldShowWhatHappened = useCallback(() => {
        const claimCause = wizardData.value.frontEndClaimCause;
        if (!claimCause) return false;
        if (claimCause === 'DamageToOthers') {
            return _.isEmpty(wizardData.value.civilLiabilityDamage)
                ? shouldShowWhatHappenedCommonPart()
                : true;
        }

        if (claimCause === 'LegalAssistanceProperty') {
            return _.isEmpty(wizardData.value.legalAssistanceDamage)
                ? shouldShowWhatHappenedCommonPart()
                : true;
        }

        if (isBikeLOB(wizardData.lob.value)) {
            return !_.isEmpty(wizardData.value.whenDidItHappen);
        }

        return shouldShowWhatHappenedCommonPart();
    }, [wizardData.value.frontEndClaimCause, wizardData.value.civilLiabilityDamage,
        wizardData.value.legalAssistanceDamage, wizardData.value.whenDidItHappen,
        wizardData.lob.value, shouldShowWhatHappenedCommonPart]);

    const shouldShowAddressSection = useCallback(() => {
        if (isLegalAssistanceAuto(wizardData.frontEndDamageTypes)) return false;

        return !!wizardData.value.whatHappened;
    }, [wizardData]);

    const shouldShowAddressInput = useCallback(() => {
        if (!_.isEmpty(wizardData.value.authenticatedFlowDetails) && !_.isEmpty(wizardData.value.authenticatedFlowDetails.addressFromPolicy)) {
            return wizardData.value.supportiveValues.lossLocationAddressType === 'other';
        }

        return true;
    }, [wizardData.value.authenticatedFlowDetails, wizardData.value.supportiveValues.lossLocationAddressType]);

    useEffect(() => {
        if (wizardData.value.frontEndClaimCause === 'Home' && wizardData.value.supportiveValues.lossLocationAddressType === undefined) {
            onAddressOptionSelect('onPolicy');
        }
    }, [wizardData.value.frontEndClaimCause, wizardData.value.supportiveValues.lossLocationAddressType, onAddressOptionSelect]);

    const buildOtherLossLocationValues = useCallback(() => {
        const values = _.get(wizardData, 'otherLossLocationType.aspects.availableValues');
        return values.map((typecode) => {
            return translateTypecodeToCodeName(translator, typecode.code, 'inlb_OtherLossLocation');
        });
    }, [translator, wizardData]);

    const lossCauseVisibility = useCallback(() => {
        const lossCauses = wizardData.value.supportiveValues.availableLossCauses;
        if (_.isEmpty(lossCauses) || lossCauses.length === 1) return false;

        return !isDamageTypeOther(wizardData, lossCauses)
            && !isLegalAssistanceProperty(wizardData, lossCauses);
    }, [wizardData]);

    const overrides = {
        pleaseWait: {
            value: isLoading
        },
        wizardFNOLBasicInformationClaimCausesQuestions: {
            visible: !_.isEmpty(wizardData.value.supportiveValues.availableClaimCauses),
            iterationData: buildClaimCausesQuestionsIterationData()
        },
        wizardFNOLBasicInformationOtherLossLocationType: {
            availableValues: buildOtherLossLocationValues(),
            visible: wizardData.value.supportiveValues.lossLocationAddressType === 'other'
        },
        wizardFNOLBasicInformationAddressDiv: {
            visible: shouldShowAddressSection(),
        },
        wizardFNOLBasicInformationAddressSelection: {
            availableValues: buildAddressAvailableValues(),
            visible: shouldShowSelectableAddress(),
            onValueChange: onAddressOptionSelect,
            value: wizardData.value.supportiveValues.lossLocationAddressType
        },
        wizardFNOLBasicInformationAddressInput: {
            onWriteValue: writeValue,
            visible: shouldShowAddressInput(),
            isCountryMandatory: true,
            isCityMandatory: isPropertyLOB(wizardData.lob.value),
            isPostalCodeMandatory: isPropertyLOB(wizardData.lob.value),
            isStreetNameMandatory: isPropertyLOB(wizardData.lob.value),
            isStreetNumberMandatory: isPropertyLOB(wizardData.lob.value)
        },
        wizardFNOLBasicInformationFirstDivider: {
            visible: !_.isEmpty(wizardData.value.supportiveValues.availableLossCauses)
        },
        wizardFNOLBasicInformationPropertyCivil: {
            visible: isDamageTypeOther(wizardData, wizardData.value.supportiveValues.availableLossCauses),
            writeValue: writeValue,
            coverages: wizardData.value.policyGICoverages,
            value: wizardData
        },
        wizardFNOLBasicInformationPropertyLegal: {
            visible: isLegalAssistanceProperty(wizardData, wizardData.value.supportiveValues.availableLossCauses),
            writeValue: writeValue,
            coverages: wizardData.value.policyGICoverages,
            value: wizardData
        },
        wizardFNOLBasicInformationLossCause: {
            availableValues: !_.isEmpty(wizardData.value.supportiveValues.availableLossCauses)
                ? wizardData.value.supportiveValues.availableLossCauses.map((entry) => translateTypecodeToCodeName(translator, entry, 'inlb_FrontEndLossCause'))
                : [],
            onValueChange: onLossCauseValueChange,
            visible: lossCauseVisibility(),
            className: isMobileDevice()
                ? 'phoneDropdownStyle'
                : 'dropdownStyle'
        },
        wizardFNOLBasicInformationPolicyNumber: {
            content: translator(messages.wizardFNOLBasicInformationPolicyNumber, { polNum: wizardData.value.policyNumber })
        },
        wizardFNOLBasicInformationDamageTypeQuestions: {
            value: wizardData,
            onCreateBasicInfoDTO: createBasicInfoDTO,
            onSetIsLoading: setIsLoading,
            visible: !_.isEmpty(wizardData.value.supportiveValues.availableDamageTypes),
            history: history
        },
        wizardFNOLBasicInformationTextArea: {
            visible: shouldShowWhatHappened(),
            validationMessages: getWhatHappenedValidationMessages
        },
        wizardFNOLBasicInformationSecondDivider: {
            visible: shouldShowWhatHappened() || shouldShowAddressSection()
        },
        wizardFNOLBasicInformationWhenDidItHappenContainer: {
            visible: isBikeLOB(wizardData.lob.value) && shouldShowWhatHappenedCommonPart(),
        },
        wizardFNOLBasicInformationWhenDidItHappen: {
            availableValues: getValuesFromTypelistIncludeFilter('com.inlb.cc.extsys.edge.anonymousfnol.fnol.dto.FnolDTO', 'whenDidItHappen', 'EBikeFlowSelectable'),
            value: wizardData.value.whenDidItHappen
        },
        wizardFNOLBasicInformationWhenDidItHappenOther: {
            visible: wizardData.value.whenDidItHappen === 'Other'
        }
    };

    const handleStepsAdjustments = useCallback(() => {
        const isCrimialDefenseAutoYes = isLegalAssistanceAuto(wizardData.frontEndDamageTypes)
            && wizardConfig.steps.length === steps.length;

        if (isCrimialDefenseAutoYes) {
            const newSteps = steps.slice(currentStepIndex + 3, steps.length);
            changeNextSteps(newSteps);
            return newSteps;
        }

        const isCrimialDefenseAutoNo = !isLegalAssistanceAuto(wizardData.frontEndDamageTypes)
            && wizardConfig.steps.length !== steps.length;

        if (isCrimialDefenseAutoNo) {
            const newSteps = wizardConfig.steps.slice(1);
            changeNextSteps(newSteps);
            return newSteps;
        }

        const isPropertyCivilLiability = isDamageTypeOther(wizardData, wizardData.value.supportiveValues.availableLossCauses);
        const isPropertyLegalAssistance = isLegalAssistanceProperty(wizardData, wizardData.value.supportiveValues.availableLossCauses);

        if (isPropertyCivilLiability || isPropertyLegalAssistance) {
            const newSteps = steps.slice(currentStepIndex + 3, steps.length);
            changeNextSteps(newSteps);
            return newSteps;
        }
        return steps;
    }, [wizardData, steps, changeNextSteps, currentStepIndex]);

    const handleOnNextClick = useCallback(() => {
        setIsLoading(true);
        const newSteps = handleStepsAdjustments();
        const wereStepsAltered = wizardConfig.steps.length !== newSteps.length;
        const nextStep = wereStepsAltered ? newSteps[0].path : newSteps[1].path;

        const valueToSend = wizardData.value;
        valueToSend.progress = getProgressBasedOnWizardStep(nextStep);
        valueToSend.isBrokerFlow = clientContext.isBrokerContext();
        return FNOLService.updateClaim(valueToSend, history, { partnerContext, translator }).then(
            (responseDTO) => {
                if (!responseDTO) return;
                wizardData.value = responseDTO;
                prepareSnapshot(responseDTO);
                return wizardData;
            }
        );
    }, [handleStepsAdjustments, wizardData, history, partnerContext, clientContext, translator]);

    const generateHeader = () => {
        return (
            <div>
                <h1>
                    {translator(messages.wizardFNOLBasicInformationTitle)}
                </h1>
                <h1>
                    {wizardData.value.claimNumber}
                </h1>
            </div>
        );
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            writeValue,
            onValidate
        },
        resolveComponentMap: {
            address: FNOLAddress,
            pleaseWait: PleaseWait,
            customRadio: CustomRadioButton,
            propertyCivil: BasicInformationPropertyCivil,
            propertyLegal: BasicInformationPropertyLegal,
            damageTypeQuestions: BasicInformationDamageTypesAndQuestionSets
        }
    };

    return (
        <WizardPage
            template={WizardPageTemplate}
            onNext={handleOnNextClick}
            disableNext={!isComponentValid}
            showPrevious={false}
            showCancel={false}
            renderContextComponent={generateHeader}
        >
            <ViewModelForm
                model={wizardData}
                uiProps={metadata.pageContent}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
                componentMap={resolvers.resolveComponentMap}
                overrideProps={overrides}
                onValueChange={writeValue}
                onValidationChange={onValidate}
            />
        </WizardPage>
    );
}

WizardFNOLBasicInformationPage.propTypes = wizardProps;
export default withRouter(WizardFNOLBasicInformationPage);
