export default {
    name: 'NNTheme',
    prefix: 'nnTheme',
    rootStyle: {
        '--GW-FONT-FAMILY': 'var(--GW-FONT-NNDAGNY)',
        '--GW-FONT-NNDAGNY': '"NN Dagny Text", "Open Sans Variable", "Source Sans Variable", Arial, sans-serif',
        '--GW-TEXT-COLOR-1': '#414141',
        '--GW-BRAND-COLOR-1': '#EE7F00',
        '--GW-BRAND-COLOR-1-HOVER': '#ea650d',
        '--GW-BRAND-COLOR-1-ACTIVE': '#e64415',
        '--GW-BRAND-COLOR-2': '#EE7F00',
        '--GW-COLOR-BACKGROUND-1': '#ffffff',
        '--GW-COLOR-BACKGROUND-2': '#ffffff',
        '--GW-ICON-FONT-SIZE-1': '0.9rem',
        '--GW-FONT-SIZE-LABEL': '1rem',
        '--GW-FONT-SIZE-BODY': '1rem',
        '--GW-HEADER-HEIGHT': '4.2rem',
        '--GW-FOCUS-COLOR': '#EE7F00',
        '--GW-FOCUS-COLOR-LIGHT': '#EE7F00'
    },
    componentStyles: {

    }
};
