/* eslint-disable max-len */
import { start } from '@jutro/app';
import { initDefaultGA, initDefaultMixpanel } from '@jutro/events';
import { LocaleService } from 'gw-portals-i18n-react';
import {
    setLanguageAndChannelFromPath
} from 'nn-capability-fnol-react/utils/UrlParamsUtil';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import nnThemeConfig from './themes/nnTheme/config';

import 'regenerator-runtime';

import App from './app/App';

const { trackingConfig = {}, authentication = {} } = appConfig;

const gaTrackingId = trackingConfig.GA_TRACKING_ID;
const mixpanelTrackingId = trackingConfig.MIXPANEL_TRACKING_ID;

if (gaTrackingId) {
    initDefaultGA(trackingConfig);
}
if (mixpanelTrackingId) {
    initDefaultMixpanel(trackingConfig);
}

let cloudAuthConfig = {};
if (authentication.authServer === 'okta') {
    cloudAuthConfig = authentication.servers.okta;
}


start(App, {
    rootId: 'root',
    messageLoader: LocaleService.loadMessages,
    coreMessageLoader: LocaleService.loadCoreMessages,
    refreshOnLocaleChange: true,
    config: [cloudAuthConfig],
    onInit: () => {
        let language;
        const { pathname } = window.location;
        const registeredLocale = new LocaleService();
        const availableLanguages = registeredLocale.getAvailableLanguages();

        // We'll be using sessionStorage to keep the setting only during browser session
        // and intialize client,channel and language from url only at first entry.
        const languageInitialized = sessionStorage.getItem('langInitialized');
        if (!languageInitialized) {
            const { languageCode, channel, client } = setLanguageAndChannelFromPath(pathname);
            language = languageCode;

            sessionStorage.setItem('channel', channel);
            sessionStorage.setItem('client', client);
            sessionStorage.setItem('langInitialized', true);
        } else {
            language = registeredLocale.getStoredLanguage().substring(0, 2).toUpperCase();
        }

        const adjustedLanguage = language === 'EN'
            ? 'en-US'
            : `${language.toLowerCase()}-${language.toUpperCase()}`;

        if (availableLanguages.indexOf(adjustedLanguage) !== -1) {
            registeredLocale.saveLanguage(adjustedLanguage);
        }

        LocaleService.register();
    },
    appName: {
        id: 'digital.appName',
        defaultMessage: 'NN Belgium - Insurance'
    },
    themeConfig: nnThemeConfig,
    appDescription: 'NN Belgium - Insurance'
});
