import { defineMessages } from 'react-intl';

export default defineMessages({
    defaultPartnerPhoneNumber: {
        id: 'anonymous.header.partner.default.phone',
        defaultMessage: '02 407 70 31'
    },
    defaultPartnerUrgentHelpLabel: {
        id: 'anonymous.header.partner.default.If you need urgent help call our NN Assist\'Line',
        defaultMessage: 'If you need urgent help call our NN Assist\'Line: {partnerPhoneNumber}'
    },
    defaultPartnerShortHelpWithNumber: {
        id: 'anonymous.partner.default.the NN Assist\’Line on',
        defaultMessage: 'the NN Assist\’Line on {partnerPhoneNumber}'
    }
});
