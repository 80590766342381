import { defineMessages } from 'react-intl';

export default defineMessages({
    demoEnvironmentLabel: {
        id: 'anonymous.fnol.header.THIS IS NOT PROD environment',
        defaultMessage: 'THIS IS NOT PROD environment'
    },
    fnolAnonymousFooterInsurer: {
        id: 'anonymous.fnol.footer.Insurer',
        defaultMessage: 'Insurer'
    },
    fnolAnonymousFooterCompanyDesc: {
        id: 'anonymous.fnol.footer.NN Non-life – VAT number BE 0801.866.930 RPR Brussels, located at Fonsnylaan 38, 1060 Brussels, under the supervision of the NBB accredited under code 2925 and authorised to take out insurance in Belgium on the basis of freedom of establishment in Belgium. Belgian branch of Nationale-Nederlanden Schadeverzekering Maatschappij N.V., insurance company under Dutch law, under the supervision of the DNB and authorised under code number NBB 2925, with registered office at Prinses Beatrixlaan 35, 2595 AK Den Haag, The Netherlands – Chamber of Commerce number 27023707',
        defaultMessage: 'NN Non-life – VAT number BE 0801.866.930 RPR Brussels, located at Fonsnylaan 38, 1060 Brussels, under the supervision of the NBB accredited under code 2925 and authorised to take out insurance in Belgium on the basis of freedom of establishment in Belgium. Belgian branch of Nationale-Nederlanden Schadeverzekering Maatschappij N.V., insurance company under Dutch law, under the supervision of the DNB and authorised under code number NBB 2925, with registered office at Prinses Beatrixlaan 35, 2595 AK Den Haag, The Netherlands – Chamber of Commerce number 27023707'
    }
});
