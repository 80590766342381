/* eslint-disable max-len */
import React, { useCallback, useContext, useMemo, useState, useEffect } from 'react';
import { renderContentFromMetadata } from '@jutro/uiconfig';
import { withRouter } from 'react-router-dom';
import { ServiceManager } from '@jutro/services';
import { TranslatorContext, LocaleService, getNativeLocaleLanguage } from '@jutro/locale';
import { Link } from '@jutro/components';
import classNames from 'classnames';
import { BreakpointTrackerContext } from '../../../../../framework/packages/@jutro/layout';
import metadata from './PageContainer.metadata.json5';
import styles from './PageContainer.module.scss';
import messages from './PageContainer.messages';
import CustomHeaderLogo from '../CustomHeaderLogo/CustomHeaderLogo';

function PageContainer(props) {
    const { children } = props;
    const translator = useContext(TranslatorContext);
    const localeService = ServiceManager.getService('locale-service');
    const breakpoint = useContext(BreakpointTrackerContext);
    const [showPhoneContextMenu, setShowPhoneContextMenu] = useState(false);
    const [phoneMenuRef, setPhoneMenuRef] = useState(false);

    const togglePhoneMenu = useCallback(() => {
        setShowPhoneContextMenu(!showPhoneContextMenu);
    }, [showPhoneContextMenu]);

    const shouldTogglePhoneMenu = useCallback((e) => {
        if (phoneMenuRef === e.target) {
            togglePhoneMenu();
        }
    }, [phoneMenuRef, togglePhoneMenu]);

    const getMenuRef = useCallback((node) => {
        setPhoneMenuRef(node);
    }, []);

    const navigateToNN = () => window.open('https://www.nn.be/');

    useEffect(() => {
        if (breakpoint === 'phone' || breakpoint === 'tablet') {
            document.addEventListener('mousedown', shouldTogglePhoneMenu, false);
            return () => document.removeEventListener('mousedown', shouldTogglePhoneMenu, false);
        }
    }, [breakpoint, shouldTogglePhoneMenu, togglePhoneMenu]);

    const getAvailableLanguages = () => {
        const availableLanguages = LocaleService.getAvailableLanguages();
        const defaultLanguages = LocaleService.settings ? LocaleService.settings.getAvailableLanguages() : [LocaleService.locale];
        const languages = availableLanguages || defaultLanguages;
        return languages.filter((language) => language !== 'de-DE');
    };


    // block drag and dropping files into unwanted places
    const dragBlockListener = (event) => {
        event.preventDefault();
        // eslint-disable-next-line no-param-reassign
        event.dataTransfer.dropEffect = 'none';
    };

    useEffect(() => {
        document.addEventListener('dragover', dragBlockListener);
        document.addEventListener('drop', dragBlockListener);
        document.addEventListener('dragenter', dragBlockListener);

        return () => {
            document.removeEventListener('dragover', dragBlockListener);
            document.removeEventListener('drop', dragBlockListener);
            document.removeEventListener('dragenter', dragBlockListener);
        };
    }, []);

    const handleLanguageValueChange = useCallback((language) => {
        localeService.saveLanguage(language);
    }, [localeService]);

    const renderLanguageLabel = useCallback((locale) => {
        const fullLabel = getNativeLocaleLanguage(locale);
        const indexOfBracket = fullLabel.indexOf('(');
        return indexOfBracket >= 0 ? fullLabel.substring(0, indexOfBracket) : fullLabel;
    }, []);

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            togglePhoneMenu,
            navigateToNN
        },
        resolveComponentMap: {
            customHeader: CustomHeaderLogo
        }
    };

    const isTestEnvironment = useMemo(() => {
        return process.env.REACT_APP_IS_TEST_ENVIRONMENT === 'true'
    }, [])

    const overrideProps = {
        headerGridContainer: {
            columns: isTestEnvironment
                ? ["auto", "auto", "auto"]
                : ["auto", "auto"]
        },
        demoEnvironmentLabel: {
            visible: isTestEnvironment
        },
        demoEnvironmentLabelPhone: {
            visible: isTestEnvironment
        },
        globalizationChooserField: {
            onLanguageValueChange: handleLanguageValueChange,
            languageValue: localeService.getStoredLanguage(),
            availableLanguageValues: getAvailableLanguages(),
            renderLanguageLabel: renderLanguageLabel
        },
        phoneMenu: {
            ref: getMenuRef,
            className: classNames('gw-phone-header', {
                'gw-phone-header-open': showPhoneContextMenu
            })
        },
        mobileHome: {
            onClick: togglePhoneMenu
        }
    };

    const headerMetadata = useMemo(() => {
        if (breakpoint === 'phone' || breakpoint === 'tablet') {
            return metadata.pageContent.content.filter((item) => item.id === 'phoneHeader');
        }
        return metadata.pageContent.content.filter((item) => item.id === 'headerFragment');
    }, [breakpoint]);

    return (
        <React.Fragment>
            {
                renderContentFromMetadata(headerMetadata, overrideProps, resolvers)
            }
            <div className={styles.pageContainer}>
                {
                    children
                }
            </div>
            <div className={styles.footerContainerDiv}>
                <p className={styles.footerHeaderTextStyle}>{translator(messages.fnolAnonymousFooterInsurer)}</p>
                <p className={styles.spanTextStyle}>{translator(messages.fnolAnonymousFooterCompanyDesc)}</p>
            </div>
        </React.Fragment>
    );
}

export default withRouter(PageContainer);
