import { defineMessages } from 'react-intl';

export default defineMessages({
    ingPartnerLogoInsuredBy: {
        id: 'anonymous.header.partner.ing.insured by',
        defaultMessage: 'insured by'
    },
    ingPartnerPhoneNumber: {
        id: 'anonymous.header.partner.ing.phone',
        defaultMessage: '02 725 15 00'
    },
    ingPartnerUrgentHelpLabel: {
        id: 'anonymous.header.partner.ing.If you need urgent help call our ING Assist\'Line',
        defaultMessage: 'If you need urgent help call our ING Assist\'Line: {partnerPhoneNumber}'
    },
    ingPartnerShortHelpWithNumber: {
        id: 'anonymous.partner.ing.the ING Assist\’Line on',
        defaultMessage: 'the ING Assist\’Line on {partnerPhoneNumber}'
    }
});
